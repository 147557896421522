import { useCallback, useEffect, useRef, useState } from "react";
import { CLOSE_ICON } from "shared/lib/helpers/images/images";
import Portal from "../portal";
import "./modal.scss";

interface IModalProps {
    children: JSX.Element;
    visible: boolean;
    onCancel: () => void;
    width?: number;
    closable?: boolean;
    disabled_padding?: boolean;
}

const ANIMDATION_DELAY = 300;

const Modal = ({ children, visible, onCancel, width = 1000, closable = true, disabled_padding = false }: IModalProps): JSX.Element | null => {
    const timerRef = useRef<ReturnType<typeof setTimeout>>();
    const [isClosing, setIsClosing] = useState<boolean>(false);
    const [classAnimation, setClassAnimation] = useState("");

    const closeModal = useCallback((): void => {
        if (closable === false) return;
        setIsClosing(true);
        timerRef.current = setTimeout(() => {
            onCancel();
            setIsClosing(false);
        }, ANIMDATION_DELAY);
    }, [onCancel]);

    const onCloseModal = (event: React.MouseEvent<HTMLSpanElement>): void => {
        event.stopPropagation();
        closeModal();
    };

    const onCloseOverlay = (event: React.MouseEvent<HTMLDivElement>): void => {
        const target = event.target as Element;
        let hasActive = target.className.includes("active");

        if (hasActive) {
            closeModal();
            return;
        }
    };

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                closeModal();
            }
        },
        [closeModal]
    );

    useEffect(() => {
        setClassAnimation(visible ? "active" : "");
    }, [visible]);

    useEffect(() => {
        if (visible) {
            window.addEventListener("keydown", onKeyDown);
            let body = document.querySelector("body");
            if (body) {
                body.style.overflow = "hidden";
            }
        }
        return () => {
            clearTimeout(timerRef.current);
            window.removeEventListener("keydown", onKeyDown);
            let body = document.querySelector("body");
            if (body) {
                body.style.overflow = "auto";
            }
        };
    }, [onKeyDown, visible]);

    if (!visible) return null;

    return (
        <Portal>
            <div className={`modal ${classAnimation ? "active" : "hide"} ${isClosing ? "hide" : ""} `} onClick={onCloseOverlay}>
                <div style={{ maxWidth: width, padding: disabled_padding ? 0 : "20px" }} className="modal_content">
                    <div className={disabled_padding ? "modal_header_absolute" : "modal_header"}>
                        {closable && (
                            <span onClick={onCloseModal}>
                                <CLOSE_ICON fill="#C1C7D0" />
                            </span>
                        )}
                    </div>
                    <div className="modal_main">{children}</div>
                </div>
            </div>
        </Portal>
    );
};

export default Modal;
