import { memo } from "react";
import "./progress.scss";

interface IProgressProps {
    progress: number;
    total: number;
    show_percent?: boolean;
}

const Progress = ({ progress, total, show_percent = true }: IProgressProps): JSX.Element => {
    const PERCENT = total > 0 ? ((progress / total) * 100).toFixed(0) : "0";

    return (
        <div className="progressbar_wrapper">
            <div className="progressbar">
                <div className="progressbar_line" style={{ width: `${PERCENT}%` }} />
            </div>
            {show_percent ? (
                <span className="progressbar_total">{PERCENT}%</span>
            ) : (
                <span className="progressbar_total">
                    {progress} / {total}
                </span>
            )}
        </div>
    );
};

export default memo(Progress, (prevProps, nextProps): boolean => {
    if (prevProps.progress === nextProps.progress && prevProps.total === nextProps.total) {
        return true;
    } else {
        return false;
    }
});
