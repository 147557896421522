import { Link, NavLink } from "react-router-dom";
import { LYD_LOGO, DASHBOARD_ICON, CALCULATE_ICON, BILLING_ICON, SETTINGS_ICON } from "shared/lib/helpers/images/images";
import { useRef, useState } from "react";
import { useAppSelector, useClickOutside } from "shared/lib/hooks";
import LogoutButton, { user_selector } from "entities/account";
import { short_username } from "shared/lib/helpers";
import { ROUTES } from "shared/config/interface";
import { Balance } from "widgets/account";
import Divider from "shared/ui/divider/divider";
import "./header.scss";

const Header = (): JSX.Element => {
    const user = useAppSelector(user_selector);
    const [activeDropdown, setActiveDropdown] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    useClickOutside(ref, () => setActiveDropdown(false));

    return (
        <header className="header">
            <div className="header_container container">
                <Link className="header_logo" to={ROUTES.DASHBOARD}>
                    <img src={LYD_LOGO} alt="LYD" />
                </Link>
                <div className="header_nav">
                    <NavLink to={ROUTES.DASHBOARD}>
                        {" "}
                        <DASHBOARD_ICON />
                        Dashboard
                    </NavLink>
                    <NavLink to={ROUTES.CALCULATOR}>
                        {" "}
                        <CALCULATE_ICON /> Calculator
                    </NavLink>
                    <NavLink to={ROUTES.BILLING}>
                        <BILLING_ICON /> Billing
                    </NavLink>
                </div>
                <div className="header_info">
                    <span onClick={() => setActiveDropdown(true)} className="header_info_name">
                        {short_username(user.username)}
                    </span>
                    <div ref={ref} className={`dropdown ${activeDropdown ? "active" : ""}`}>
                        <div className="dropdown_top">
                            <span className="header_info_name">{short_username(user.username)}</span>
                            <span>
                                {user.username} <br /> {user.email}
                            </span>
                        </div>
                        <Divider />
                        <Balance />
                        <Divider />
                        {/* <NavLink style={{ opacity: 0.2, pointerEvents: "none" }} to={ROUTES.SETTINGS} className="dropdown_link">
                            <SETTINGS_ICON />
                            Settings
                        </NavLink> */}
                        <LogoutButton />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
