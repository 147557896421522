import { memo, useEffect, useRef, useState } from "react";
import { CLOSE_ICON } from "shared/lib/helpers/images/images";
import Modal from "../modal/modal";
import { DEFAULT_ACCEPT_FILES } from "shared/config/config";
import "./upload-file-list.scss";

interface IUploadFileListProps {
    max?: number;
    setFiles: (files: File[]) => void;
    fileList: File[];
    accept?: string;
}

const UploadFileList = ({ max = 4, setFiles, fileList, accept = DEFAULT_ACCEPT_FILES }: IUploadFileListProps): JSX.Element => {
    const inputRef = useRef<HTMLInputElement | any>();
    const [fileListLocal, setFileListLocal] = useState<File[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<File | null>(null);
    const [dragActive, setDragActive] = useState<boolean>(false);

    useEffect(() => {
        setFiles(fileListLocal);
    }, [fileListLocal]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        if ((e.target.files as FileList).length > max) {
            alert(`${max} files only`);
            return;
        }
        if (e.target.files && e.target.files[0]) {
            setFileListLocal([...Array.from(e.target.files)]);
        }
    };

    const remove = (file: File): void => {
        setFileListLocal(fileList.filter((element: File) => element.name !== file.name));
    };

    const onShowImage = (file: File): void => {
        setCurrentImage(file);
        setModalVisible(true);
    };

    function onDragStart(e: React.DragEvent<HTMLDivElement>): void {
        e.preventDefault();
        setDragActive(true);
    }

    function onDragLeave(e: React.DragEvent<HTMLDivElement>): void {
        e.preventDefault();
        setDragActive(false);
    }

    function onDrop(e: React.DragEvent<HTMLDivElement>): void {
        e.preventDefault();
        let files: File[] = [...Array.from(e.dataTransfer.files)];
        setFileListLocal([...files]);
        const formData = new FormData();
        formData.append("file", files[0]);
        setDragActive(false);
    }

    function onCLoseModal(): void {
        setModalVisible(false);
        setCurrentImage(null);
    }

    return (
        <div className="uload_container">
            {currentImage && (
                <Modal width={900} visible={modalVisible} onCancel={onCLoseModal}>
                    <>
                        <h4>{currentImage.name}</h4>
                        <img className="upload_big_preview" src={URL.createObjectURL(currentImage)} alt="preview" />
                    </>
                </Modal>
            )}
            <div className="uload_preview_wrap">
                {Array.from(fileList).map((file: File) => (
                    <div key={file.name} className="uload_preview">
                        <span onClick={() => remove(file)} className="uload_preview_remove">
                            <CLOSE_ICON fill="white" />
                        </span>
                        <span onClick={() => onShowImage(file)} className="uload_preview_show">
                            Open
                        </span>
                        <img key={file.name} src={URL.createObjectURL(file)} alt="preview" />
                    </div>
                ))}
            </div>
            {Array.from(fileList).length < max && (
                <div onDragStart={onDragStart} onDragLeave={onDragLeave} onDragOver={onDragStart} onDrop={onDrop} onClick={() => inputRef.current.click()} className="uload_container_btn">
                    <input multiple={true} ref={inputRef} type="file" id="uload_element" onChange={handleChange} max={max} maxLength={max} accept={accept} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M5.25033 9.33333V5.83333H2.91699L7.00033 1.75L11.0837 5.83333H8.75033V9.33333H5.25033ZM2.91699 11.6667V10.5H11.0837V11.6667H2.91699Z" fill="#A5ADBA" />
                    </svg>
                    Upload files(max {max})
                </div>
            )}
        </div>
    );
};

export default memo(UploadFileList);
