import Select from "react-select";
import { customStylesSelect } from "shared/config/config";
import { ISelectOptions, LanguageType } from "shared/config/interface";
import { Modal } from "shared/ui";
import { accountApiSlice } from "../model/api/api";
import { useEffect, useState } from "react";
import { useAppDispatch } from "shared/lib/hooks";
import { set_current_organization } from "../model/slice/account-slice";

interface IProps {
    organizationList: { id: number; name: string }[];
    language: LanguageType;
    userID: number;
}

const ChooseOrganization = ({ organizationList, language, userID }: IProps): JSX.Element => {
    const [setCurrentOrganization, { isLoading, data }] = accountApiSlice.useSetCurrentOrganizationMutation();
    const [selectOrganizationVisible, setSelectOrganizationVisible] = useState<boolean>(true);
    const [organizationListNew, setOrganizationListNew] = useState<ISelectOptions[]>([] as any);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (data) {
            dispatch(set_current_organization(data));
        }
    }, [data, dispatch]);

    useEffect(() => {
        setSelectOrganizationVisible(true);
        const organizations = organizationList;
        let new_arr: ISelectOptions[] = organizations.map((el) => ({ value: String(el.id), label: el.name, readonly: true }));
        setOrganizationListNew(new_arr);
    }, [organizationList]);

    return (
        <Modal visible={selectOrganizationVisible} onCancel={() => setSelectOrganizationVisible(false)} closable={false}>
            <div style={{ textAlign: "center", padding: 20 }}>
                <h1 data-testid="choose_organization" style={{ marginBottom: 40 }}>
                    Please select your organization
                </h1>
                <Select
                    isLoading={isLoading}
                    styles={customStylesSelect}
                    onChange={(value: any): any => {
                        setCurrentOrganization({
                            language: language,
                            user: userID,
                            organization_current: +value.value
                        });
                    }}
                    options={organizationListNew as any}
                    placeholder="Select organization"
                />
            </div>
        </Modal>
    );
};

export default ChooseOrganization;
