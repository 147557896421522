import $api from "shared/api/api";
import { IBalance, IClientException, IConfig, IContactUsPayload, ISetHistory, IUpdateCurrentHistoryResponse, IUser } from "../types/types";
import { API_ENDPOINTS, API_TAGS, KEEP_UNUSED_DATA_FOR } from "shared/config/config";

export const accountApiSlice = $api.injectEndpoints({
    endpoints: (builder) => ({
        logout: builder.mutation<any, void>({
            query: () => ({
                url: `authentication/logout/`,
                method: "POST"
            })
        }),
        me: builder.query<IUser, void>({
            query: () => ({
                url: `authentication/user/me`,
                method: "GET"
            })
        }),
        balance: builder.query<IBalance, number>({
            query: (organization_id) => ({
                url: `${API_ENDPOINTS.account}balance/${organization_id}/`,
                method: "GET",
                providesTags: [API_TAGS.Balance]
            })
        }),
        setCurrentOrganization: builder.mutation<IUpdateCurrentHistoryResponse, ISetHistory>({
            query: (data) => ({
                url: `${API_ENDPOINTS.authentication}user/${data.user}/history/`,
                method: "POST",
                body: {
                    language: data.language,
                    organization_current: data.organization_current
                }
            })
        }),
        config: builder.query<IConfig, null>({
            query: () => ({
                url: `${API_ENDPOINTS.account}config/`,
                method: "GET"
            }),
            keepUnusedDataFor: KEEP_UNUSED_DATA_FOR
        }),
        captureClientException: builder.mutation<IClientException, IClientException>({
            query: (data) => ({
                url: `core/log/`,
                method: "POST",
                body: {
                    ...data
                }
            })
        }),
        contactUs: builder.mutation<IContactUsPayload, IContactUsPayload>({
            query: (data) => ({
                url: `core/contact-us/`,
                method: "POST",
                body: data
            })
        })
    })
});
