import { Link } from "react-router-dom";
import { ROUTES } from "shared/config/interface";

const SignInLinks = (): JSX.Element => {
    return (
        <>
            <span className="authorization_link">
                <Link to={ROUTES.RESET_PASSWORD}>Forgot password</Link>
            </span>
            <span className="authorization_link">
                Don't have an account yet? <Link to={ROUTES.REGISTRATION}>Sign up</Link>
            </span>
        </>
    );
};

export default SignInLinks;
