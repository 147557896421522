import { FAIL_ICON, SUCCESS_ICON } from "shared/lib/helpers/images/images";
import "./useNotification.scss";

interface INotificationContent {
    message: string;
    duration?: number;
    onClose?: (id: string) => void;
}

interface IOpenNotification {
    message: string;
    duration?: number;
    type: NotificationType;
    onClose: undefined | ((id: string) => void);
}

interface IcreateRootWrapper {
    root_wrapper: HTMLCollectionOf<HTMLBodyElement>;
    node_el: HTMLDivElement;
}

type NotificationType = "success" | "error";
// type ReturnNotification = {
//     notification: Record<NotificationType, (args: INotificationContent) => void>;
// };
type NotificationFunction = (args: INotificationContent) => void;

export const useNotification = (): Record<NotificationType, NotificationFunction> => {
    const ICONS: { [type: string]: string } = {
        success: SUCCESS_ICON,
        error: FAIL_ICON
    };

    let notification: Record<NotificationType, NotificationFunction> = {
        success: ({ message, duration, onClose }: INotificationContent) =>
            openNotification({
                type: "success",
                message,
                duration,
                onClose
            }),
        error: ({ message, duration, onClose }: INotificationContent) =>
            openNotification({
                type: "error",
                message: message,
                duration,
                onClose
            })
    };

    let { node_el, root_wrapper } = createRootWrapper();

    function createRootWrapper(): IcreateRootWrapper {
        const root_wrapper: HTMLCollectionOf<HTMLBodyElement> = document.getElementsByTagName("body");
        const check_wrapper: HTMLDivElement | null = document.querySelector(".notification_wrap");

        if (!check_wrapper) {
            let node_el: HTMLDivElement = document.createElement("div");
            node_el.classList.add(`notification_wrap`);
            return { node_el, root_wrapper };
        }

        return { node_el: check_wrapper, root_wrapper };
    }

    function openNotification({ type, message, duration = 5, onClose = undefined }: IOpenNotification): void {
        const ID = `${Math.round(Math.random() * 1000)}_${type}`;

        node_el.insertAdjacentHTML(
            "afterbegin",
            `<div id='${ID}' class="notification ${type} ">
            <img src='${ICONS[type]}'/>
            <span>${message}</span></div>
        `
        );
        root_wrapper[0].appendChild(node_el);
        closeNotification(ID, duration, onClose);
    }

    function closeNotification(ID: string, duration: number, onClose: any): void {
        setTimeout(() => {
            const elem = document.getElementById(ID);
            if (elem) {
                elem.classList.add(`hide`);
                if (onClose) {
                    onClose(ID);
                }

                elem.remove();
            }
        }, duration * 1000);
    }

    return notification;
};
