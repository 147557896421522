import { Link } from "react-router-dom";
import { Button } from "shared/ui";
import { ROUTES } from "shared/config/interface";
import BalanceInfo from "./balance-info";
import "./balance.scss";

const Balance = (): JSX.Element => {
    return (
        <div className="balance_widget">
            <BalanceInfo />
            <Button style={{ paddingInline: 48 }} size="small" typeStyle="black_border">
                <Link to={ROUTES.BILLING}>Top up the balance</Link>
            </Button>
        </div>
    );
};

export default Balance;
