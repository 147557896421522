import { GlobalStatuses, IBill } from "shared/config/interface";

export interface ICreateBilling {
    amount: number;
    organization_id: number;
}

export type BillingStatusTypes = GlobalStatuses.WAITING | GlobalStatuses.PENDING | GlobalStatuses.PAID | GlobalStatuses.FAILED | GlobalStatuses.CANCELLED | GlobalStatuses.COST;

export interface ICreateBillingResponse {
    id: number;
    amount: string;
    status: BillingStatusTypes;
    organization_id: number;
    payment_intent: string;
    client_secret: string;
}

export interface IBilling {
    id: number;
    amount: string;
    status: BillingStatusTypes;
    created_date: string;
    task: {
        id: number;
        project_name: number;
        name: string;
        bill: IBill[];
    } | null;
}

export interface IBillingState {
    loading: boolean;
    isShowPaymentForm: boolean;
    client_secret_key: string | null;
    sumValue: string | number;
}

export const TH_BILLINGS: string[] = ["Transaction", "Amount, $", "Status", "Created"];
