import { GoogleOAuthProvider } from "@react-oauth/google";
import { FC } from "react";

type IProps = {
    children: React.ReactNode;
};

const GoogleAuthWrapper: FC<IProps> = ({ children }): JSX.Element => {
    const google_clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "test";

    if (!google_clientId) {
        alert("Missing Google client id");
    }

    return (
        <GoogleOAuthProvider clientId={google_clientId as string} onScriptLoadError={() => alert("Error Google auth provider")}>
            {children}
        </GoogleOAuthProvider>
    );
};

export default GoogleAuthWrapper;
