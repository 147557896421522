export function local_logger(message: string, ...args: any): void {
    if (process.env.NODE_ENV === "production") return;
    let style = `
        background: #10eee8;
        padding: 3px;
        font-weight: 700;
        font-size: 11px;
        color: black;
    `;
    console.log(`%c@  ${message} ----  @`, style);
    console.log(...args);
}
