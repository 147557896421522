import { IBilling, BillingStatusTypes, TH_BILLINGS } from "./model/types/interface";
import billingSlice, { setSumValue, clearBillingData, setIsShowPayment, setClientSecretKey } from "./model/slice/billing-slice";
import { is_show_payment_form_selector, client_secret_key_selector, sum_value_selector } from "./model/selectors/billing-selectors";
import { useCreateBillingMutation, useLazyBillingQuery } from "./model/api/api";

export {
    billingSlice,
    is_show_payment_form_selector,
    client_secret_key_selector,
    sum_value_selector,
    setSumValue,
    useCreateBillingMutation,
    useLazyBillingQuery,
    clearBillingData,
    setIsShowPayment,
    setClientSecretKey,
    TH_BILLINGS as TH
};

export type { IBilling, BillingStatusTypes };
