import { S3Client, PutObjectCommand, DeleteObjectCommand, PutObjectCommandInput, DeleteObjectCommandInput } from "@aws-sdk/client-s3";
import { ERROR_CODE_AWS, ROOT_FOLDER } from "shared/config/config";
import { AWS_UPLOAD_FILE_RESPONSE } from "shared/config/interface";

const REGION = process.env.REACT_APP_AWS_S3_REGION;
const ACCES_KEY = process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY;
const BUCKET_NAME = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
const BUCKET_URL: string = `https://${process.env.REACT_APP_AWS_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_S3_REGION}.amazonaws.com/`;

class AWS {
    static s3: S3Client;

    static configure(): void {
        if (!ACCES_KEY || !SECRET_ACCESS_KEY || !BUCKET_NAME) return alert("Please provide AWS credentials");

        this.s3 = new S3Client({
            region: REGION,
            credentials: {
                accessKeyId: String(ACCES_KEY),
                secretAccessKey: String(SECRET_ACCESS_KEY)
            }
        });
    }

    static async upload_file(file: any, folder: string): Promise<AWS_UPLOAD_FILE_RESPONSE | number | undefined> {
        const FULL_FOLDER = ROOT_FOLDER + folder;
        const fileExtension = file.name.split(".").pop().toLowerCase();
        let contentType: string;

        switch (fileExtension) {
            case "pdf":
                contentType = "application/pdf";
                break;
            case "docx":
                contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                break;
            default:
                return ERROR_CODE_AWS;
        }

        const bucket_params: PutObjectCommandInput = {
            Bucket: BUCKET_NAME,
            Key: FULL_FOLDER,
            Body: file,
            ContentType: contentType,
            ContentDisposition: "inline"
        };

        try {
            const data = await this.s3.send(new PutObjectCommand(bucket_params));

            if (data) {
                const { httpStatusCode } = data.$metadata;

                if (httpStatusCode === 200) {
                    return {
                        link: BUCKET_URL + FULL_FOLDER
                    };
                }
            }
        } catch (error) {
            return ERROR_CODE_AWS;
        }
    }
    static async remove_file(link: string): Promise<any> {
        const bucketParams: DeleteObjectCommandInput = {
            Bucket: BUCKET_NAME,
            Key: this.get_folder_from_url(link)
        };

        try {
            const data = await this.s3.send(new DeleteObjectCommand(bucketParams));
            return data;
        } catch (error) {
            return ERROR_CODE_AWS;
        }
    }

    private static get_folder_from_url(file_url: string): string {
        let folder: string = file_url.substring(BUCKET_URL.length, file_url.length);
        return folder;
    }
}

AWS.configure();

export default AWS;
