import { memo } from "react";
import "./information.scss";

interface IProps {
    image: JSX.Element;
    title: string;
    description: string;
}

const Information = ({ description, image, title }: IProps): JSX.Element => {
    return (
        <div className="information_block" data-testid="information_block">
            {image}
            <h2 className="information_block_h2">{title}</h2>
            <p className="information_block_p">{description}</p>
        </div>
    );
};

export default memo(Information);
