import { LanguageType, ORGANIZATIONS, ROLES } from "shared/config/interface";

export type OrhanizationCurrentType = IOrganizationShortFormat & { collaborator_cvat_ids: number[] };

export interface IUser {
    email: string;
    username: string;
    date_joined: string;
    first_name: string | null;
    last_name: string | null;
    date_of_birth: string | null;
    id: number;
    cvat_id: number;
    is_staff: boolean;
    is_active: boolean;
    onboarding: string[];
    language: LanguageType;
    groups: ROLES[];
    account_type: ORGANIZATIONS;
    organizations: IOrganizationShortFormat[];
    organization_current: OrhanizationCurrentType;
    premium?: boolean;
}

export interface IAccountState {
    user: IUser;
    is_auth: boolean;
    balance: IBalance;
    create_organization_modal_visible: boolean;
}

export interface IBalance {
    available_balance: number;
    involved_budgets: number | null;
    minimum_amount: number;
    tasks_cost_in_progress: number;
}

export interface IOrganizationShortFormat {
    id: number;
    name: string;
    cvat_id: number;
    owner: ICollaborator;
}

export enum ORGANIZATION_PAGE_TABS {
    MEMBERS = "members",
    API = "api"
}
export interface ICollaborator {
    id: number;
    username: string;
    email: string;
}
export interface IOrganization extends IOrganizationShortFormat {
    owner: ICollaborator;
    collaborators: ICollaborator[];
}

export interface IApiKeyInfo {
    created: Date;
    prefix: string;
    key_name: string;
}

export interface ISetHistory {
    language: LanguageType;
    user: number;
    organization_current: number;
}

export interface IUpdateCurrentHistoryResponse {
    language: LanguageType;
    organization_current: OrhanizationCurrentType;
}

export interface IConfig {
    files_limit_upload_per_task: number;
    files_limit_upload_per_pilot_task: number;
    segment_size: number;
    files_size_limit: number;
}

export interface IClientException {
    user_agent: string;
    platform: string;
    language: string;
    location_pathname: string;
    user_id?: number;
    message: string;
    stack: string;
    time_stamp: string;
    screen: number | string;
}

export interface IContactUsPayload {
    email: string;
    message: string;
    subject: string;
}
