import "./button.scss";
import { SPIN_SVG } from "shared/lib/helpers/images/images";
import { IButtonProps } from "./model/config/type";

/**
 * Button.
 *
 * @param {ButtonProps} props button's props
 * @returns {React.ReactElement<ButtonProps>} Button.
 */

const Button: React.FC<IButtonProps> = ({ children, typeStyle = "default", icon, htmlType = "button", size = "medium", className = "", disabled = false, loading = false, ...props }): JSX.Element => {
    let classes = [`btn ${typeStyle} ${size} ${className}`];
    let loading_spin = <SPIN_SVG />;

    return (
        <button disabled={disabled} type={htmlType} className={classes.join(" ")} {...props}>
            {loading ? (
                loading_spin
            ) : (
                <>
                    {icon && icon}
                    {children}
                </>
            )}
        </button>
    );
};

export default Button;
