import FacebookLogin, { FailResponse, ProfileSuccessResponse, SuccessResponse } from "@greatsumini/react-facebook-login";
import { FACEBOOK_ICON } from "shared/lib/helpers/images/images";
import { Button } from "shared/ui";

type Props = {
    onSuccess: (response: SuccessResponse) => void;
    onFail: (error: FailResponse) => void;
    onProfileSuccess: (response: ProfileSuccessResponse) => void;
    registration?: boolean;
    loading: boolean;
};

const FacebookButtonWrapper = ({ onSuccess, onFail, onProfileSuccess, registration = false, loading }: Props): JSX.Element => {
    return (
        <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
            onSuccess={onSuccess}
            onFail={onFail}
            onProfileSuccess={onProfileSuccess}
            // fields="name,email,picture"
            // scope="public_profile,user_friends,user_actions.books"
            render={({ onClick, logout }) => (
                <Button loading={loading} disabled={loading} className="authorization_facebook" size="small" icon={<img src={FACEBOOK_ICON} alt="Facebook" />} typeStyle="facebook" onClick={onClick}>
                    {registration ? "Sign up with Facebook" : "Sign in with Facebook"}
                </Button>
            )}
        />
    );
};

export default FacebookButtonWrapper;
