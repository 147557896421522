import AWS from "shared/api/aws";
import { AWS_UPLOAD_FILE_RESPONSE } from "shared/config/interface";
import { replace_spaces } from "shared/lib/helpers";

export const upload_instruction = async (id: number, project_name: string, pdf_instruction: Blob, username: string): Promise<number | undefined | AWS_UPLOAD_FILE_RESPONSE> => {
    let new_project_name = replace_spaces(project_name);
    let folder: string = `${username}-${id}/${new_project_name}/instruction/${new_project_name}_instruction.pdf`;
    const aws = await AWS.upload_file(pdf_instruction, folder);

    return aws;
};
