import AWS from "shared/api/aws";
import { AWS_UPLOAD_FILE_RESPONSE } from "shared/config/interface";
import { replace_spaces } from "shared/lib/helpers";

export const upload_instruction = async (id: number, project_name: string, instruction_file: Blob, username: string): Promise<number | undefined | AWS_UPLOAD_FILE_RESPONSE> => {
    let new_project_name = replace_spaces(project_name);

    const fileExtension = instruction_file.type === "application/pdf" ? "pdf" : "docx";
    const fileNameSuffix = fileExtension === "pdf" ? "_instruction.pdf" : "_instruction.docx";

    let folder: string = `${username}-${id}/${new_project_name}/instruction/${new_project_name}${fileNameSuffix}`;

    const aws = await AWS.upload_file(instruction_file, folder);

    return aws;
};
