import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "shared/config/config";
import { TOKENS } from "shared/config/interface";
import { api_endpoint } from "./api_endpoint";

const URL_API = api_endpoint();

const $api = createApi({
    reducerPath: "endpoints",
    baseQuery: fetchBaseQuery({
        baseUrl: URL_API,
        credentials: "include",
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(TOKENS.TOKEN);

            if (token) {
                headers.set("Authorization", `Token ${token}`);
            }

            return headers;
        }
    }),
    tagTypes: [API_TAGS.Project, API_TAGS.Balance],
    refetchOnReconnect: true,
    refetchOnFocus: true,
    endpoints: (builder) => ({
        projectById: builder.query<any, { id: number }>({
            query: ({ id }) => ({
                url: `account/project/${id}`,
                method: "GET"
            })
        })
    })
});

export default $api;
