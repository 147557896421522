import { useDeleteProjectMutation, useCreateProjectMutation } from "./api/api";
import createProjectSlice, {
    createProjectData,
    setCurrentProjectTypeView,
    setCurrentStep,
    set_edit_mode,
    add_label,
    set_show_attributes,
    remove_label,
    update_original_label,
    set_edit_original_name,
    set_quick_upload,
    set_show_drawer,
    clearProjectDetails,
    setIsLoadingCreateProject
} from "./model/slice/create-project-slice";
import { ICreateProject, ICreateProjectSettings, IFormData, ICreateProjectPayload, ITaskPriceOptions } from "./model/types/interface";

import { COMPUTER_VISION_PROJECT, INITIAL_FORM_VALUES, EDITOR_CONFIG } from "./model/types/config";
import {
    create_project_data_selector,
    edit_mode_selector,
    show_attributes_selector,
    labels_selector,
    edit_original_name_selector,
    quick_upload_mode_selector,
    show_drawer_selector,
    current_step_selector,
    is_loading_create_project_selector
} from "./model/selectors/create-project-selectors";

export type { ICreateProject, ICreateProjectSettings, IFormData, ICreateProjectPayload, ITaskPriceOptions };

export {
    createProjectSlice,
    createProjectData,
    setCurrentStep,
    COMPUTER_VISION_PROJECT,
    create_project_data_selector,
    setCurrentProjectTypeView,
    INITIAL_FORM_VALUES,
    edit_mode_selector,
    show_attributes_selector,
    labels_selector,
    edit_original_name_selector,
    quick_upload_mode_selector,
    show_drawer_selector,
    set_edit_mode,
    add_label,
    set_show_attributes,
    remove_label,
    update_original_label,
    set_edit_original_name,
    set_quick_upload,
    set_show_drawer,
    current_step_selector,
    is_loading_create_project_selector,
    useDeleteProjectMutation,
    useCreateProjectMutation,
    clearProjectDetails,
    setIsLoadingCreateProject,
    EDITOR_CONFIG
};
