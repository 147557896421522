import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAccountState, IBalance, IUpdateCurrentHistoryResponse, IUser } from "../types/types";
import { accountApiSlice } from "../api/api";
import { TOKENS } from "shared/config/interface";

const INITIAL_STATE: IAccountState = {
    user: {} as IUser,
    is_auth: Boolean(localStorage.getItem("token")) || false,
    balance: {
        available_balance: 0,
        involved_budgets: 0,
        minimum_amount: 0,
        tasks_cost_in_progress: 0
    }
};

const accountSlice = createSlice({
    name: "authentication",
    initialState: INITIAL_STATE,
    reducers: {
        logout: (state) => {
            state.user = {} as IUser;
            state.is_auth = false;
            localStorage.removeItem(TOKENS.TOKEN);
            localStorage.removeItem(TOKENS.TOKEN_CVAT);
            localStorage.removeItem(TOKENS.ONE_TIME_TOKEN);
            localStorage.removeItem(TOKENS.ONE_TIME_TOKEN_TIMESTAMP);
        },
        set_user(state, action) {
            state.user = action.payload;
        },
        set_is_auth(state, action: PayloadAction<boolean>) {
            state.is_auth = action.payload;

            if (action.payload === false) {
                state.user = {} as IUser;
            }
        },
        set_current_organization(state, action: PayloadAction<IUpdateCurrentHistoryResponse>) {
            state.user = {
                ...state.user,
                organization_current: action.payload.organization_current
            };
        },
        update_balance(state, action: PayloadAction<{ available_balance: number; involved_budgets: number }>) {
            state.balance = {
                ...state.balance,
                ...action.payload
            };
        }
    },
    extraReducers(builder) {
        builder.addMatcher(accountApiSlice.endpoints.balance.matchFulfilled, (state, action: PayloadAction<IBalance>) => {
            state.balance = action.payload;
        });
    }
});

export default accountSlice.reducer;
export const { set_user, set_is_auth, set_current_organization, logout, update_balance } = accountSlice.actions;
