import { ATTRIBUTE_TYPE, AttributeType, ISelectOptions, ISelectTypeOptions } from "./interface";

export const customStylesSelect = {
    menuList: (provided: any, state: any) => ({
        ...provided,
        padding: 0
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        borderRadius: "5px",
        padding: "12px 15px",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 500,
        color: state.isFocused ? "white" : "#000000",
        backgroundColor: state.isFocused ? "black" : "#ffffff",
        "&:hover": {
            backgroundColor: "black",
            color: "white"
        },
        "&:active": {
            backgroundColor: "black",
            color: "white"
        },
        transition: "0.2s all"
    }),
    placeholder: (base: any) => ({
        ...base,
        fontSize: "14px",
        color: "#a5adba",
        fontWeight: 500
    }),
    control: (base: any, state: any) => ({
        ...base,
        borderRadius: "5px",
        border: state.isFocused || state.menuIsOpen ? "1px solid #0b0b0b" : "1px solid #C1C7D0",
        padding: "0px 7px",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 500,
        color: "#a5adba",
        width: "100%",
        minHeight: "42px",
        backgroundColor: "#ffffff",
        boxShadow: "none",
        "&:hover": {
            borderColor: "#0b0b0b"
        },
        transition: "0.2s all"
    })
};

export const ANNOTATION_TOOLS: ISelectOptions[] = [
    { value: "rectangle", label: "Rectangle" },
    { value: "polygon", label: "Polygon" },
    { value: "cuboid", label: "Cuboid" },
    { value: "points", label: "Keypoint" }
];

export const PROJECT_TYPES_SELECT: ISelectOptions[] = [
    { value: "object_detection", label: "Object detection" },
    // { value: "object_tracking", label: "Object tracking" },
    { value: "object_segmentation", label: "Segmentation" },
    { value: "ocr", label: "OCR" },
    { value: "data_collection", label: "Data collection" },
    { value: "other", label: "Other" }
];

export const ATTRIBUTE_TYPES: ISelectTypeOptions<AttributeType>[] = [
    { value: ATTRIBUTE_TYPE.SELECT, label: "Select" },
    { value: ATTRIBUTE_TYPE.RADIO, label: "Radio" },
    { value: ATTRIBUTE_TYPE.CHECKBOX, label: "Checkbox" },
    { value: ATTRIBUTE_TYPE.TEXT, label: "Text" },
    { value: ATTRIBUTE_TYPE.NUMBER, label: "Number" }
];

export const PLATFORM_TYPE: "client" = "client";
export const CVAT_URL = process.env.REACT_APP_HOSTNAME_CVAT;
export const ETA_HOURS = 72;
export const ERROR_CODE_AWS = 400;

export const CREATE_TASK_DEFAULT_DATA = {
    platform: PLATFORM_TYPE,
    due_date: "2025-09-13T06:13:06.411Z",
    labels: [],
    level_complexity: 1
};

export const TASK_CONFIGURATION: Record<string, string> = {
    image_quality: "100",
    use_cache: "true",
    use_zip_chunks: "true"
};

export const INSTRUCTIONS_PLACEHOLDER: Record<string, string> = {
    description_instruction: "Description of labels and attributes",
    summary_instruction: "Summary of the task",
    workflow_instruction: "Workflow"
};

export const API_TAGS = {
    Project_CVAT: "Project_CVAT",
    Task_CVAT: "Task_CVAT",
    Project: "Project",
    Balance: "Balance",
    Organization: "Organization",
    Create_Organization: "Create_Organization",
    Api_Key: "Api_Key",
    Current_organization: "Current_organization"
};

export const API_ENDPOINTS = {
    account: "account/",
    projects_history: "account/projects-history/",
    authentication: "authentication/"
};

export const DEFAULT_ACCEPT_FILES = "image/*";
export const INSTRUCTION_ACCEPT_FILES = ".png, .jpg, .jpeg";
export const EXTERNAL_API_PLATFORM_TYPE = "platform=client";
export const LOCALE_STRIPE = "en";
export const PAGE_SIZE = 10;
export const CREATE_PROJECT_MODAL_WIDTH = 1200;
export const KEEP_UNUSED_DATA_FOR = 600000;
export const PILOT_TASK_NAME = "pilot-task";
export const ACCEPT_IMAGES = ".png, .jpeg, .jpg, .gif, .jp2, .webp, .bmp, .psd, .raw";
export const ROOT_FOLDER = "clients_platform/";

export const STRIPE_KEY = process.env.REACT_APP_PUBLISHABLE_KEY || "";
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const IS_REACT_APP_DEV = process.env.REACT_APP_NODE_CONFIGURATION === "development";
