import { RootState } from "app/store-config";
import { ILabel, PROJECT_TYPE } from "shared/config/interface";
import { ICreateProject } from "../types/interface";

export const current_step_selector = (state: RootState): number => state.createProject.current_step;
export const current_project_type_selector = (state: RootState): PROJECT_TYPE => state.createProject.current_project_type_view;
export const create_project_data_selector = (state: RootState): ICreateProject => state.createProject.create_project;
export const is_loading_create_project_selector = (state: RootState): boolean => state.createProject.isLoadingCreateProject;
export const edit_mode_selector = (state: RootState): boolean => state.createProject.edit_mode;
export const labels_selector = (state: RootState): ILabel[] => state.createProject.labels;
export const show_attributes_selector = (state: RootState): boolean => state.createProject.show_attributes;
export const quick_upload_mode_selector = (state: RootState): boolean => state.createProject.quick_upload_mode;
export const show_drawer_selector = (state: RootState): boolean => state.createProject.show_drawer;
export const edit_original_name_selector = (state: RootState): string => state.createProject.edit_original_name;
