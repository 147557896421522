import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { rules, show_error } from "shared/lib/helpers";
import { EYE_ICON, GOOGLE_ICON } from "shared/lib/helpers/images/images";
import { useAppDispatch, useFacebookAuth, useGoogleAuth, useNotification, useToggle } from "shared/lib/hooks";
import { Button, Divider, FacebookButtonWrapper, FormItem, Input, Loader } from "shared/ui";
import { ErrorAuth, useLogin, ILogin, useLoginMutation } from "entities/authorization";
import { set_is_auth } from "entities/account";
import { Mixpanel } from "shared/lib/services/mixpanel";
import { MIXPANEL_TRACK_KEYS } from "shared/config/interface";

const SignInForm = (): JSX.Element => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    }: any = useForm<ILogin>({
        mode: "onBlur"
    });

    const dispatch = useAppDispatch();
    const notification = useNotification();
    const { login } = useLogin();

    const [loginAsync, { isLoading }] = useLoginMutation();
    const { isLoading: isLoadingGoogle, isSuccess, data, google_login, error: googleError } = useGoogleAuth();
    const { onFail, onProfileSuccess, onSuccess, data: dataFacebook, isLoading: isLoadingFacebook, isSuccess: isSuccessFacebook } = useFacebookAuth();

    const { isToggle, toggleHandler } = useToggle(false);

    async function onSubmitForm(data: ILogin): Promise<void> {
        try {
            const result: any = await loginAsync({ user: data });
            if (result?.data) {
                login(result.data);
                dispatch(set_is_auth(true));
                Mixpanel.track(MIXPANEL_TRACK_KEYS.LOGIN, {
                    name: data.username,
                    $name: data.username
                });
                return;
            }
            if (result.error) {
                show_error(true, result.error as ErrorAuth, notification);
            }
        } catch (error) {
            notification.error({
                message: error as string,
                duration: 6
            });
        }
    }

    useEffect(() => {
        if (googleError) {
            notification.error({
                message: `Google login error: ${googleError.data.detail}`
            });
        }
    }, [googleError, notification]);

    useEffect(() => {
        if (isSuccess && data) {
            Mixpanel.track(MIXPANEL_TRACK_KEYS.LOGIN_GOOGLE, {});
            login(data);
        }
    }, [isSuccess, data, login]);

    useEffect(() => {
        if (isSuccessFacebook && dataFacebook) {
            Mixpanel.track(MIXPANEL_TRACK_KEYS.LOGIN_FACEBOOK, {});
            login(dataFacebook);
        }
    }, [isSuccessFacebook, dispatch, dataFacebook, login]);

    if (isLoading || isLoadingFacebook) {
        <Loader />;
    }

    return (
        <>
            <form id="signin-form" data-testid="signin-form" onSubmit={handleSubmit(onSubmitForm)}>
                <FormItem label="User Name" error={errors?.username?.message}>
                    <Input {...register("username", rules.username)} type="text" placeholder="Your User Name" data-testid="sign-uinusername" className={errors?.username?.message ? "error" : ""} />
                </FormItem>
                <FormItem label="Password" error={errors?.password?.message}>
                    <Input
                        {...register("password", rules.password)}
                        type={isToggle ? "text" : "password"}
                        placeholder="Your password"
                        data-testid="sign-in-password"
                        className={errors?.password?.message ? "error" : ""}
                    />
                    <span className="eye" onClick={toggleHandler}>
                        <EYE_ICON />
                    </span>
                </FormItem>
            </form>
            <Button loading={isLoading || isLoadingGoogle} disabled={isLoading || isLoadingGoogle} form="signin-form" className="authorization_main_btn" size="large" typeStyle="danger" type="submit">
                Sign in
            </Button>
            <Divider text="OR" />
            <div className="authorization_btn_wrapper">
                <Button
                    loading={isLoading || isLoadingGoogle}
                    disabled={isLoading || isLoadingGoogle}
                    className="authorization_google"
                    size="large"
                    icon={<img src={GOOGLE_ICON} alt="Google" />}
                    typeStyle="google"
                    onClick={() => google_login()}
                >
                    Sign in with Google
                </Button>
                <FacebookButtonWrapper loading={isLoading || isLoadingGoogle} onSuccess={onSuccess} onFail={onFail} onProfileSuccess={onProfileSuccess} />
            </div>
        </>
    );
};

export default SignInForm;
