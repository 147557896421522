import { memo } from "react";
import { useForm } from "react-hook-form";
import { Button, FormItem, Information, Input, Modal } from "shared/ui";
import { useAppSelector } from "shared/lib/hooks";
import { IContactUsPayload, account_api, user_email_selector, user_username_selector } from "entities/account";
import { COMPLETED_ICON } from "shared/lib/helpers/images/images";
import { Mixpanel } from "shared/lib/services/mixpanel";
import { MIXPANEL_TRACK_KEYS } from "shared/config/interface";
import "./contact-us.scss";

interface IContactUsProps {
    title: string;
    visible: boolean;
    onClose: () => void;
}

interface IContactUsForm {
    email: string;
    full_name: string;
    message: string;
}

const ContactUs = ({ title, onClose, visible }: IContactUsProps): JSX.Element => {
    const username = useAppSelector(user_username_selector);
    const email = useAppSelector(user_email_selector);

    const [sendContacuUs, { isLoading, isSuccess, reset: resetMutation }] = account_api.useContactUsMutation();

    const {
        register,
        trigger,
        formState: { errors },
        getValues,
        reset
    } = useForm<IContactUsForm>({
        mode: "onBlur",
        defaultValues: {
            email: email,
            full_name: username
        }
    });

    async function onSubmitForm(event: React.MouseEvent<HTMLButtonElement>): Promise<void> {
        if (!(await trigger("message"))) return;

        let data = getValues();

        let final_data: IContactUsPayload = {
            email: data.email,
            message: data.message,
            subject: title
        };

        sendContacuUs(final_data);
        Mixpanel.track(MIXPANEL_TRACK_KEYS.CONTACT_US, { message: data.message, theme: title });
    }

    function onCloseForm(): void {
        resetMutation();
        reset();
        onClose();
    }

    if (isSuccess) {
        return (
            <Modal visible={true} onCancel={() => onCloseForm()} width={455}>
                <Information title="Success!" description="Your message has been successfully sent!" image={<COMPLETED_ICON />} />
            </Modal>
        );
    }

    return (
        <Modal visible={visible} onCancel={onCloseForm} width={455} title={title}>
            <form>
                <FormItem label="Email adress" error={errors.email?.message}>
                    <Input data-testid="contact_us_email" disabled {...register("email")} type="email" placeholder="Your email" className={errors?.email?.message ? "error" : ""} />
                </FormItem>
                <FormItem label="Full name" error={errors?.full_name?.message}>
                    <Input data-testid="contact_us_full_name" disabled {...register("full_name")} type="text" placeholder="Your full name" className={errors?.full_name?.message ? "error" : ""} />
                </FormItem>
                <FormItem label="How can we help?" error={errors?.message?.message}>
                    <textarea
                        {...register("message", {
                            required: "Message is required field"
                        })}
                        className="contactus_textarea"
                        maxLength={500}
                        rows={4}
                        placeholder="e.g. I’m looking to set up external API of Label Your Data"
                    />
                </FormItem>
                <div className="contactus_btn_wrapper">
                    <Button data-testid="contact_us_cancel" disabled={isLoading} onClick={onCloseForm} size="small" typeStyle="default">
                        Cancel
                    </Button>
                    <Button onClick={onSubmitForm} data-testid="contact_us_confirm" disabled={isLoading} loading={isLoading} size="small">
                        Confirm
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default memo(ContactUs);
