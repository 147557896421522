import LYD_LOGO from "../../../assets/icons/lyd-logo.png";
import GOOGLE_ICON from "../../../assets/icons/google.png";
import FACEBOOK_ICON from "../../../assets/icons/face.svg";
import { ReactComponent as DASHBOARD_ICON } from "../../../assets/icons/dashboard.svg";
import { ReactComponent as CALCULATE_ICON } from "../../../assets/icons/calculate.svg";
import { ReactComponent as BILLING_ICON } from "../../../assets/icons/billing.svg";
import { ReactComponent as STATISTIC_ICON } from "../../../assets/icons/statistic.svg";
import { ReactComponent as BELL_ICON } from "../../../assets/icons/bell.svg";
import { ReactComponent as SETTINGS_ICON } from "../../../assets/icons/settings.svg";
import { ReactComponent as LOGOUT_ICON } from "../../../assets/icons/logout.svg";
import { ReactComponent as DATA_ICON } from "../../../assets/icons/data.svg";
import { ReactComponent as ADD_ICON } from "../../../assets/icons/add.svg";
import { ReactComponent as EDIT_ICON } from "../../../assets/icons/edit.svg";
import { ReactComponent as HELP_ICON } from "../../../assets/icons/help.svg";
import { ReactComponent as BUSKET_ICON } from "../../../assets/icons/minus.svg";
import { ReactComponent as UPLOAD_ICON } from "../../../assets/icons/upload.svg";
import { ReactComponent as UPLOADFILE_ICON } from "../../../assets/icons/upload_file.svg";
import { ReactComponent as CLOSE_ICON } from "../../../assets/icons/close.svg";
import { ReactComponent as EMAIL_ICON } from "../../../assets/icons/email.svg";
import { ReactComponent as ROCKET_ICON } from "../../../assets/icons/rocket.svg";
import { ReactComponent as DUMP_ICON } from "../../../assets/icons/dump.svg";
import { ReactComponent as FILE_ICON } from "../../../assets/icons/file.svg";
import { ReactComponent as FILE_GREY_ICON } from "../../../assets/icons/file-2.svg";
import { ReactComponent as SORT_ICON } from "../../../assets/icons/sort.svg";
import { ReactComponent as MORE_ICON } from "../../../assets/icons/more.svg";
import { ReactComponent as EYE_ICON } from "../../../assets/icons/eye.svg";
import { ReactComponent as EYE_BIG_ICON } from "../../../assets/icons/eye-big.svg";
import { ReactComponent as SPIN_SVG } from "../../../assets/spin.svg";
import { ReactComponent as SENT_EMAIL } from "../../../assets/icons/sent-email.svg";
import { ReactComponent as PAYMENT_HISTORY_ICON } from "../../../assets/icons/payment-history.svg";
import { ReactComponent as LOCK_ICON } from "../../../assets/icons/lock.svg";
import { ReactComponent as COMPLETED_ICON } from "../../../assets/icons/completed.svg";
import { ReactComponent as ADD_FOLDER_ICON } from "../../../assets/icons/add-folder.svg";
import { ReactComponent as FOLDER_ICON } from "../../../assets/icons/folder.svg";
import { ReactComponent as ADD_WHITE_ICON } from "../../../assets/icons/add-white.svg";
import { ReactComponent as INSTRUCTION_ICON } from "../../../assets/icons/instruction.svg";
import { ReactComponent as BREAD_CRUMBS_ICON } from "../../../assets/icons/bread-crumbs.svg";
import { ReactComponent as EXPORT_ICON } from "../../../assets/icons/export.svg";
import { ReactComponent as PAUSE_ICON } from "../../../assets/icons/pause.svg";
import { ReactComponent as COMPLETED_TASK_ICON } from "../../../assets/icons/completed-task.svg";
import { ReactComponent as PLAY_ICON } from "../../../assets/icons/play.svg";
import { ReactComponent as FILE_SMALL_ICON } from "../../../assets/icons/file-small.svg";
import { ReactComponent as ADD_SMALL_ICON } from "../../../assets/icons/add-small.svg";
import { ReactComponent as LOADER_WRAP_ICON } from "../../../assets/icons/loader-wrap.svg";
import ERROR from "../../../assets/pictures/error.png";
import SUCCESS_ICON from "../../../assets/icons/success-icon.png";
import FAIL_ICON from "../../../assets/icons/failed-icon.png";
import WATERMAK from "../../../assets/pictures/watermark.png";
export {
    LYD_LOGO,
    GOOGLE_ICON,
    FACEBOOK_ICON,
    DASHBOARD_ICON,
    CALCULATE_ICON,
    BILLING_ICON,
    STATISTIC_ICON,
    BELL_ICON,
    SETTINGS_ICON,
    LOGOUT_ICON,
    DATA_ICON,
    ADD_ICON,
    EDIT_ICON,
    HELP_ICON,
    BUSKET_ICON,
    UPLOAD_ICON,
    UPLOADFILE_ICON,
    CLOSE_ICON,
    EMAIL_ICON,
    ROCKET_ICON,
    DUMP_ICON,
    ERROR,
    SUCCESS_ICON,
    FAIL_ICON,
    FILE_ICON,
    FILE_GREY_ICON,
    SORT_ICON,
    MORE_ICON,
    WATERMAK,
    EYE_ICON,
    SPIN_SVG,
    PAYMENT_HISTORY_ICON,
    SENT_EMAIL,
    LOCK_ICON,
    COMPLETED_ICON,
    ADD_FOLDER_ICON,
    FOLDER_ICON,
    ADD_WHITE_ICON,
    INSTRUCTION_ICON,
    BREAD_CRUMBS_ICON,
    EYE_BIG_ICON,
    EXPORT_ICON,
    PAUSE_ICON,
    COMPLETED_TASK_ICON,
    PLAY_ICON,
    FILE_SMALL_ICON,
    ADD_SMALL_ICON,
    LOADER_WRAP_ICON
};
