import { Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Layout from "widgets/layouts/layout";
import { LayoutAuth } from "widgets/authorization";
import { is_auth_selector } from "entities/account";
import { useAppSelector } from "shared/lib/hooks";
import { Loader } from "shared/ui";
import {
    BillingPage,
    CreateProjectPage,
    DashboardPage,
    ProectPage,
    SignInPage,
    SignUpPage,
    ResetPassword,
    ResetPasswordNew,
    ConfirmEmail,
    CalculatorPage,
    OrganizationPage,
    TermsOfUsePage,
    PrivacyPage
} from "pages";
import { ROUTES } from "shared/config/interface";

const LazyLoad = ({ children }: { children: React.ReactNode }): JSX.Element => <Suspense fallback={<Loader />}>{children}</Suspense>;

const authenticatedRoutes = [
    {
        path: ROUTES.DASHBOARD,
        element: <Layout />,
        children: [
            {
                index: true,
                element: (
                    <LazyLoad>
                        <DashboardPage />
                    </LazyLoad>
                )
            },
            {
                path: ROUTES.CALCULATOR,
                element: (
                    <LazyLoad>
                        <CalculatorPage />
                    </LazyLoad>
                )
            },
            {
                path: ROUTES.BILLING,
                element: (
                    <LazyLoad>
                        <BillingPage />
                    </LazyLoad>
                )
            },
            {
                path: `${ROUTES.BILLING}/:client_secret`,
                element: (
                    <LazyLoad>
                        <BillingPage />
                    </LazyLoad>
                )
            },
            {
                path: ROUTES.BILLING_SUCCESS,
                element: (
                    <LazyLoad>
                        <BillingPage />
                    </LazyLoad>
                )
            },
            {
                path: ROUTES.CREATE_PROJECT,
                element: (
                    <LazyLoad>
                        <CreateProjectPage />
                    </LazyLoad>
                )
            },
            {
                path: `${ROUTES.PROJECT}/:project_history_id/:cvat_project_id`,
                element: (
                    <LazyLoad>
                        <ProectPage />
                    </LazyLoad>
                )
            },
            {
                path: `${ROUTES.ORGANIZATION}/:sub`,
                element: (
                    <LazyLoad>
                        <OrganizationPage />
                    </LazyLoad>
                )
            },
            {
                path: `${ROUTES.TERMS}`,
                element: (
                    <LazyLoad>
                        <TermsOfUsePage />
                    </LazyLoad>
                )
            },
            {
                path: `${ROUTES.PRIVACY}`,
                element: (
                    <LazyLoad>
                        <PrivacyPage />
                    </LazyLoad>
                )
            },
            { path: "*", element: <Navigate replace to={ROUTES.DASHBOARD} /> }
        ]
    }
];

const unauthenticatedRoutes = [
    {
        element: <LayoutAuth />,
        children: [
            {
                path: ROUTES.LOGIN,
                element: (
                    <LazyLoad>
                        <SignInPage />
                    </LazyLoad>
                )
            },
            {
                path: ROUTES.CHECK_REGISTRATION_EMAIL,
                element: (
                    <LazyLoad>
                        <SignInPage />
                    </LazyLoad>
                )
            },
            {
                path: ROUTES.REGISTRATION,
                element: (
                    <LazyLoad>
                        <SignUpPage />
                    </LazyLoad>
                )
            },
            {
                path: ROUTES.RESET_PASSWORD,
                element: (
                    <LazyLoad>
                        <ResetPassword />
                    </LazyLoad>
                )
            },
            {
                path: ROUTES.RESET_PASSWORD_NEW,
                element: (
                    <LazyLoad>
                        <ResetPasswordNew />
                    </LazyLoad>
                )
            },
            {
                path: ROUTES.CONFIRM_EMAIL,
                element: (
                    <LazyLoad>
                        <ConfirmEmail />
                    </LazyLoad>
                )
            },
            { path: "*", element: <Navigate replace to={ROUTES.LOGIN} /> }
        ]
    }
];

const AppRoutes: React.FC = () => {
    const isAuth = useAppSelector(is_auth_selector);

    const routes = useRoutes(isAuth ? authenticatedRoutes : unauthenticatedRoutes);

    return <>{routes}</>;
};

export default AppRoutes;
