import { ChangeEvent, DragEvent, useCallback, useRef, useState } from "react";
import { UPLOAD_ICON } from "shared/lib/helpers/images/images";
import { Alert, Button } from "shared/ui";
import { useNotification } from "shared/lib/hooks";
import UploadFileList from "./upload-file-list";
import "./upload-file.scss";

interface IProps {
    setFiles: (files: File[]) => void;
    files: File[];
    maxFiles?: number;
    files_size_limit?: number;
}

const UploadFile = ({ setFiles, files, maxFiles = 10, files_size_limit = 1024 }: IProps): JSX.Element => {
    const ref = useRef<HTMLInputElement>(null);
    const notification = useNotification();

    const [dragActive, setDragActive] = useState<boolean>(false);
    const [visibleFileFormatError, setVisibleFileFormatError] = useState<boolean>(false);
    const [sizeLimitError, setSizeLimitError] = useState<boolean>(false);

    const handleDrag = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setDragActive(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setDragActive(false);
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        const droppedFiles: File[] = Array.from(e.dataTransfer.files);

        if (droppedFiles.length > maxFiles) {
            notification.error({
                message: `Maximum ${maxFiles} files can be uploaded at a time.`
            });
            return;
        }
        setFiles(droppedFiles);
        setDragActive(false);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files) {
            const inputFiles: File[] = Array.from(e.target.files);
            if (inputFiles.length > maxFiles) {
                notification.error({
                    message: `Maximum ${maxFiles} files can be uploaded at a time.`
                });
                return;
            }

            let files_size: number = 0;
            Array.from(e.target.files).forEach((file: File) => (files_size += file.size));

            if (files_size / 1024 / 1024 > files_size_limit) {
                setSizeLimitError(true);
                notification.error({
                    message: `Maximum files size - 1024MB`
                });
                return;
            }

            setSizeLimitError(false);
            setFiles(inputFiles);
        }
    };

    const onRemoveFile = useCallback(
        (file: File): void => {
            let filtered_files = files.filter((fileItem) => fileItem.name !== file.name);
            setFiles(filtered_files);
        },
        [files]
    );

    const deleteAll = (): void => {
        setFiles([]);
    };

    return (
        <div className="upload">
            {files.length < 1 && (
                <div onDragOver={handleDrag} onDragEnter={handleDrag} onDragLeave={handleDragLeave} onDrop={handleDrop} className={`upload_main ${dragActive ? "active" : ""}`}>
                    <label htmlFor="upload" className="upload_label">
                        <input maxLength={maxFiles} data-testid="file-input" multiple ref={ref} type="file" id="upload" onChange={handleChange} style={{ display: "none" }} />
                        <UPLOAD_ICON />
                        <b>Drag & Drop your batch files here</b>
                        <small>OR</small>
                        <Button size="small" data-testid="file-upload-browse-file" onClick={() => ref.current?.click()} typeStyle="default">
                            Choose files
                        </Button>
                    </label>
                </div>
            )}
            {files.length > 0 && (
                <div className="upload_info">
                    <p>Files uploaded: {files.length}</p>
                    <span onClick={deleteAll}>Delete all</span>
                </div>
            )}
            <UploadFileList files={files} onRemoveFile={onRemoveFile} />
            <div className="upload_message">
                {visibleFileFormatError && <Alert type="error" text="Error! File format is not valid." />}
                {sizeLimitError && <Alert type="error" text="Maximum files size - 1024MB" />}
                <Alert type="warning" text={files.length === maxFiles ? "Limited to 10 frames. To label more, generate a few extra tasks." : `Regular task is limited to ${maxFiles} frames.`} />
            </div>
        </div>
    );
};

export default UploadFile;
